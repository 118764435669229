import type { Locale } from 'next/router'

import type { DownloadableFile } from '@/types'
import { dateTranslations } from '@/utils/plain/dateTranslations.ts'
import { languageAndLocaleMapping } from '@/utils/plain/languageAndLocaleMapping'

export const getFileInfo = (
  file: DownloadableFile,
  locale: Locale,
  pageTranslation: string,
) => {
  const date = new Date(file.updatedAt)
  const monthIndex = date.getMonth()
  const year = date.getFullYear()
  let formattedDate = ''
  if (locale in dateTranslations) {
    const localeData = dateTranslations[locale as keyof typeof dateTranslations]
    const translatedMonth = localeData.month.short[monthIndex]
    formattedDate = localeData.dateFormat
      .replace(/M+/g, translatedMonth)
      .replace('YYYY', String(year))
  } else {
    formattedDate = date.toLocaleDateString(languageAndLocaleMapping[locale], {
      month: 'short',
      year: 'numeric',
    })
  }
  const dateAndSize = `.${file.type} / 
  ${formattedDate} / 
${file.size}`

  return file.numberOfPages! > 0
    ? `${dateAndSize} / 
  ${pageTranslation}`
    : ` ${dateAndSize}`
}
